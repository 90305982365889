class Common {
  constructor() {
    this.header = document.querySelector('#header');
    this.body = document.querySelector('body');
    this.footer = document.querySelector('footer');
    this.setOptimizedResizeEvent();
  }

  registerEvents() {
    const self = this;
    self.header
      .querySelector('.header-check')
      .addEventListener('change', function() {
        const headerClass = self.header.querySelector('.header-list').classList;
        const bodyClass = self.body.classList;
        const footerClass = self.footer.classList;

        if (this.checked) {
          headerClass.remove('fadeout-splist');
          setTimeout(() => {
            headerClass.add('open');
            bodyClass.add('open-menu');
            footerClass.add('open-footer');
          }, 50);
          return;
        }
        headerClass.add('fadeout-splist');
        footerClass.remove('open-footer');
      });

    self.header
      .querySelector('.header-list')
      .addEventListener('animationend', () => {
        const headerClass = self.header.querySelector('.header-list').classList;
        const bodyClass = self.body.classList;

        headerClass.remove('open');
        bodyClass.remove('open-menu');
      });

    window.addEventListener('optimizedResize', () => {
      const chkBox = self.header.querySelector('.header-check');
      if (window.innerWidth > 750) {
        let headerElem = this.header.querySelector('.header-list');
        if (chkBox.checked) {
          chkBox.checked = false;
          this.triggerEvent(chkBox, 'change');
          this.triggerEvent(headerElem, 'animationend');
        }

        if (headerElem.classList.contains('fadeout-splist')) {
          headerElem.classList.remove('fadeout-splist');
        }
      }
    });
  }

  setOptimizedResizeEvent = () => {
    const throttle = function(type, name, obj) {
      const objAttached = obj || window;
      let running = false;
      const func = function() {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(() => {
          objAttached.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      objAttached.addEventListener(type, func);
    };

    /* init - you can init any event */
    throttle('resize', 'optimizedResize');
  };

  triggerEvent = (element, event) => {
    if (document.createEvent) {
      // IE以外
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent(event, true, true); // event type, bubbling, cancelable
      return element.dispatchEvent(evt);
    }
    // IE
    const evt = document.createEventObject();
    return element.fireEvent(`on${event}`, evt);
  };
}

if (document.querySelector('#header')) {
  const common = new Common();
  common.registerEvents();
}
